<!-- @format -->

<template>
  <div class="flex flex-col gap-8">
    <div class="border-2 border-gray-100 rounded-md bg-white">
      <SurveyQuestionsTable
        v-if="statsLoaded"
        :tableData="practiceStats?.data"
        :tableHeadings="surveyTableHeadings"
        :detailPage="true"
        :actionPlanPageRoute="actionPlanPageRoute"
        :key="practiceStats?.data"
      />
      <Pagination
        :currentPage="practiceStats?.current_page"
        :totalPages="practiceStats?.last_page"
        @page-changed="fetchPracticeStats"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import SurveyQuestionsTable from "@/components/newUi/tables/SurveyQuestionsTable.vue";
import Pagination from "@/components/newUi/atoms/Pagination.vue";

const route = useRoute();
const store = useStore();
const practiceStats = ref(null);
const statsLoaded = ref(false);
const actionPlanPageRoute = ref("hq-PS-ActionPlan");

const questionId = route.params.id;

onMounted(() => {
  fetchPracticeStats(1);
});

const surveyTableHeadings = ref([
  "Practice",
  "Percentage",
  "Average",
  "Action",
]);

const fetchPracticeStats = async (page) => {
  await store
    .dispatch("patientSurveys/fetchPracticeStats", {
      survey_question: questionId,
      page: page,
    })
    .then((response) => {
      statsLoaded.value = true;
      practiceStats.value = response;
    });
};
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
</style>
